<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Job Settings</h3>
            </v-col>
            <v-col 
                style="text-align: right;"
                cols="6"
                lg="2"
                md="4"
                sm="6"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'jobSettingsForm', params: {id: 0} }"
                        >
                            New Setting
                        </v-btn>
                    </template>
                    <span>New Setting</span>
                </v-tooltip>
        </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    no-data-text="No Record Found"
                >
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'jobSettingsForm'"
                            :showButtons="{
                                edit: true,
                                delete: true,
                            }"
                            @confirmDelete="confirmDelete"  />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList,
            ActionDialog
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Module", value: "moduleSelected.moduleDescription", sortable: true },
                { text: "Panel", value: "panelSelected.panelDescription", sortable: true },
                { text: "Stage", value: "stageSelected.stageDescription", sortable: true },
                { text: "Level", value: "levelSelected.levelDescription", sortable: true },
                { text: "Days", value: "days", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listJobSettings: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listJobSettings.filter((jobSettingsFilter) => {
                    
                    const filter = this.filter.fastSearch.toLowerCase();

                    const moduleDescription = jobSettingsFilter.moduleSelected != null ? jobSettingsFilter.moduleSelected.moduleDescription.toLowerCase().match(filter) : ''
                    const panelDescription = jobSettingsFilter.panelSelected != null ? jobSettingsFilter.panelSelected.panelDescription.toLowerCase().match(filter) : ''
                    const stageDescription = jobSettingsFilter.stageSelected != null ? jobSettingsFilter.stageSelected.stageDescription.toLowerCase().match(filter) : ''
                    const levelDescription = jobSettingsFilter.levelSelected != null ? jobSettingsFilter.levelSelected.levelDescription.toLowerCase().match(filter) : ''
                    const id = jobSettingsFilter.id.toString().match(filter);

                    if (moduleDescription != null) { return moduleDescription; } 
                    else if(panelDescription != null) { return panelDescription; } 
                    else if(stageDescription != null) { return stageDescription; } 
                    else if(levelDescription != null) { return levelDescription; } 
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {

                    case 1:
                        color = 'var(--color__main)';
                        break;

                    case 0:
                        color = 'var(--color__cinza_escuro)';
                        break;
                
                    default:
                        break;
                }

                return color
            },
            
            async getRegisters() {

                this.listJobSettings = await this.$store.dispatch("serviceSettingsModule/List");
            },

            confirmDelete(id) {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: 'You will DELETE this Job Setting, confirm your decision?',
                    methodConfirm: this.delete,
                    params: id
                };
            },

            async delete(id) {

                this.showLoading();

                let result = await this.$store.dispatch("serviceSettingsModule/Delete", id);

                if (result.success) {
                    this.showToast("success", "Success!", result.message);
                }
                else {
                    this.showToast("error", "Error!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

        },
        
        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>
